.table-attitudes thead {
    color: var(--font-second-color);
    border: solid 1px #AA90B4;
    background: #AA90B4;
}

.table-attitudes thead th,
.table-attitudes tbody tr {
    border-bottom: solid 1px #CCC;
}

.table-attitudes tbody td {
    border-right: solid 1px #CCC;
}

.table-attitudes tbody tr td:first-child {
    color: var(--font-second-color);
    border-left: solid 1px #CCC;
    background: #AA90B4;
}

@media (max-width: 768px) {
    
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}
