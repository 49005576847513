/*-- site general css --*/
* {
    box-sizing: border-box;
}

:root {
    --font-primary-color: #333;
    --font-second-color: #FFF;
}

@font-face {
    font-family: "Quick Sand";
    src: url("assets/fonts/quicksand.ttf");
}

html, body {
    overflow-x: hidden;
    position: relative;
}

body {
    font-size: 16px;
    line-height: 22px;
    color: var(--font-primary-color);
    font-family: "Quick Sand", sans-serif;
    background: url(assets/img/background.webp) no-repeat center center fixed;
    background-size: cover;
}

h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
}

a {
    text-decoration: none;
    transition: .5s;
}

a,
a:hover,
a:focus,
a:visited {
    color: var(--font-primary-color);
}

table,
.img-content {
    width: 100%;
}

.table-responsive {
    overflow-x: auto;
}

table {
    border-collapse: collapse;
}

table th,
table td {
    padding: 15px;
    text-align: center;
    vertical-align: middle;
}

img {
    max-width: 100%;
}

.hidden,
.menu-back,
.icon-navbar {
    display: none;
}

.container {
    background: var(--font-second-color);
}

.App,
.header-title-wrapper {
    position: relative;
}

.header-wrapper {
    top: 42px;
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    z-index: 999;
}

.header-content {
    padding: 15px;
    box-shadow: 0 4px 2px -2px rgb(41,41,41, .3);
}

.header-title {
    margin: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.content-wrapper {
    padding-top: 122px;
}
/*-- end site general css --*/


@media (max-width: 768px) {
    table th,
    table td {
        padding: 5px;
    }

    .header-wrapper {
        top: 0;
    }

    .header-title {
        font-size: 21px;
        line-height: 29px;
    }

    .header-content {
        padding: 5px 10px;
    }

    .menu-back,
    .icon-navbar {
        display: flex;
    }

    .content-wrapper {
        padding-top: 60px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .header-wrapper {
        top: 0;
    }

    .header-content {
        padding: 5px 10px;
    }

    .header-title {
        font-size: 21px;
        line-height: 29px;
    }

    .menu-back,
    .icon-navbar {
        display: flex;
    }

    .content-wrapper {
        padding-top: 60px;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}