.yokai-header-wrapper {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1280px;
    max-width: 100%;
    background: #E85A3D;
    position: fixed;
    z-index: 999;
}

.img-yokai-detail-wrapper {
    width: 100%;
    aspect-ratio: 2 / 1;
    position: relative;
}

.yokai-number,
.img-tribe-detail,
.img-attribute-detail,
.img-rank-detail {
    position: absolute;
}

.img-tribe-detail,
.img-attribute-detail,
.img-rank-detail {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.yokai-number {
    top: 10px;
    left: 10px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
}

.img-tribe-detail {
    right: 10px;
    bottom: 10px;
}

.img-attribute-detail {
    left: 10px;
    bottom: 10px;
}

.img-rank-detail {
    top: 10px;
    right: 10px;
}

.bg-img-yokai-detail,
.img-yokai-detail {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.bg-img-yokai-detail {
    background: rgba(255,255,255, .6);
}

.img-yokai-detail {
    padding: 50px;
    object-fit: contain;
}

.yokai-food,
.yokai-detail-profile-content {
    padding: 15px;
}

.yokai-detail-profile-content {
    text-align: justify;
}

.yokai-detail-profile-content p {
    margin: 0;
}

.yokai-food {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-yokai-detail-food {
    margin-right: 15px;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.yokai-detail-title {
    margin: 0;
    padding: 15px;
    text-align: center;
    color: var(--font-second-color)
}

.yokai-detail-stats,
.yokai-detail-evolution {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.yokai-detail-stats div {
    padding: 15px;
    width: 100%;
    text-align: center;
}

.stats-hp {
    background: #FFD2A5;
}

.stats-str {
    background: #FF8989;
}

.stats-spr {
    background: #CFA7ed;
}

.stats-def {
    background: #77BAFF;
}

.stats-spd {
    background: #E2FF8e;
}

.yokai-detail-stats p {
    margin: 0;
    text-align: center;
}

.yokai-detail-evolution {
    padding: 15px;
}

.yokai-detail-evolution:not(:last-child) {
    border-bottom: solid 1px #CCC;
}

.yokai-detail-evolution .evolution {
    width: 100%;
    text-align: center;
}

.yokai-detail-evolution .evolution a {
    display: inline-block;
}

.yokai-detail-evolution .evolution.arrow,
.yokai-detail-evolution .evolution.plus,
.yokai-detail-evolution .evolution.equal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-evolution-arrow {
    width: 50%;
}

.img-evolution {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.yokai-detail-evolution .evolution p {
    margin: 0;
}

.img-technique,
.img-soultimate-1 {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.yokai-socials-wrapper {
    padding: 15px;
    text-align: center;
}

.social-share-button:not(:last-child) {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .img-yokai-detail-wrapper {
        aspect-ratio: 1 / 1;
    }

    .img-tribe-detail,
    .img-attribute-detail,
    .img-rank-detail {
        width: 35px;
        height: 35px;
    }
    
    .yokai-food,
    .yokai-detail-stats div,
    .yokai-detail-evolution {
        padding: 10px;
    }

    .img-evolution-arrow {
        width: 100%;
    }

    .yokai-detail-title {
        font-size: 16px;
        line-height: 22px;
    }

    .img-evolution {
        width: 75px;
        height: 75px;
    }

    .yokai-detail-moveset-content td {
        padding: 10px;
    }

    .img-technique-wrapper,
    .img-soultimate-1-wrapper {
        padding: 0 !important;
    }

    .img-technique,
    .img-soultimate-1 {
        width: 100%;
        height: 25px;
    }

    .img-soultimate-2 {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

    .yokai-socials-wrapper {
        padding: 10px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}
