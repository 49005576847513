.container {
    margin: 0 auto;
    width: 1280px;
    max-width: 100%;
}

.medallium {
    padding-top: 192px;
}

.medallium .header-content {
    box-shadow: none;
}

.yokai-filter-wrapper,
.yokai-search-wrapper {
    width: 100%;
}

.yokai-filter-wrapper,
.yokai-filter-left,
.yokai-filter-right {
    display: flex;
    justify-content: space-between;
}

.yokai-filter-wrapper {
    top: 122px;
    width: 1280px;
    max-width: 100%;
    padding: 0 18px 18px 18px;
    background: #E85A3D;
    position: fixed;
    z-index: 999;
}

.yokai-filter-left {
    width: 70%;
}

.yokai-filter-right {
    width: 29%;
}

.icon-search {
    top: 0;
    left: 18px;
    padding: 15px;
    width: 52px;
    height: 52px;
    object-fit: contain;
    position: absolute
}

.yokai-search {
    padding: 15px 15px 15px 52px;
    width: 100%;
    height: 52px;
    border-radius: 5px;
    font-size: 18px;
    border: none;
}

#filter-rank,
#filter-attribute,
#filter-tribe {
    padding: 15px;
    width: 31%;
    border: none;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../img/icon-down.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    background-color: var(--font-second-color);
}

.yokai-search:focus {
    outline: none;
}

.medallium-list ul {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
    background: #C3C3C3;
}

.medallium-list li {
    list-style: none;
}

.yokai-list-pc-wrapper ul {
    padding-left: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 23.5%;
    justify-content: flex-start;
}

.yokai-list-pc-wrapper .yokai-content-pc-wrapper {
    margin-top: 18px;
    margin-right: 1.5%;
    padding: 50px 40px;
    width: 23.5%;
    border-radius: 10px;
    border-right: solid 5px #333;
    border-bottom: solid 5px #333;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notfound {
    font-weight: 700;
    text-align: center;
}

.meddallium-yokai-number-pc,
.img-trible-pc,
.img-rank-pc,
.img-attribute-pc,
.bg-color-pc {
    position: absolute;
}

.bg-color-pc {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 5px 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 65%);
    background: rgba(255,255,255, .5);
    display: block;
    position: absolute;
}

.meddallium-yokai-number-pc {
    top: 10px;
    left: 10px;
}

.meddallium-yokai-number-pc,
.yokai-name-pc {
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
}

.img-trible-pc,
.img-rank-pc,
.img-attribute-pc {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.img-trible-pc {
    right: 10px;
    bottom: 10px;
}

.img-rank-pc {
    top: 16px;
    right: 10px;
}

.img-yokai-pc {
    width: 250px;
    height: 250px;
    object-fit: contain;
    position: relative;
    z-index: 1;
}

.img-attribute-pc {
    left: 10px;
    bottom: 10px;
}

.yokai-name-pc-wrapper {
    margin-top: 10px;
    text-align: center;
}

.meddallium-yokai-number-pc,
.yokai-name-pc {
    padding: 10px;
    border-right: solid 3px #333;
    border-bottom: solid 3px #333;
    border-radius: 10px;
    display: inline-block;
}

.yokai-name-pc {
    margin-bottom: 0;
    background: rgba(255,255,255, .5);
}

.medallium-list .yokai-content,
.yokai-left-wrapper,
.yokai-right-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.medallium-list .yokai-content {
    padding: 10px;
    justify-content: space-between;
    position: relative;
}

.yokai-left-wrapper,
.yokai-right-wrapper {
    z-index: 1;
}

.bg-color {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(255,255,255, .5);
    position: absolute;
    z-index: 0;
}

.img-yokai-wrapper {
    padding: 15px;
    width: 115px;
    height: 115px;
    border-radius: 50%;
    background: var(--font-second-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.img-yokai {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 125px;
    height: 125px;
    object-fit: contain;
    position: absolute;
}

.yokai-name-wrapper {
    margin-left: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.meddallium-yokai-number,
.yokai-name {
    padding: 3px 13px;
    border-radius: 6px;
    font-weight: 700;
    border-right: solid 2px #333;
    border-bottom: solid 2px #333;
}

.yokai-name {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 23px;
    line-height: 32px;
    background: rgba(255,255,255, .6);
}

.img-attribute,
.img-rank,
.img-trible {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.img-attribute,
.img-trible {
    margin-left: 15px;
}

.img-yokai-wrapper,
.img-yokai {
    width: 75px;
    height: 75px;
}

.yokai-name-wrapper {
    margin-left: 15px;
}

.yokai-name {
    font-size: 16px;
    line-height: 22px;
}

.no-yokai-wrapper {
    padding-top: 18px;
    padding-right: 18px;
    font-weight: 700;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.icon-notfound {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .medallium {
        padding-top: 158px;
    }

    .medallium-header-wrapper {
        padding: 10px;
    }

    .yokai-filter-wrapper {
        padding: 0 7px 7px 7px;
        top: 60px;
        flex-direction: column;
    }

    .yokai-filter-left,
    .yokai-filter-right,
    .yokai-search {
        width: 100%;
    }

    .yokai-filter-left {
        margin-bottom: 7px;
    }

    .yokai-search,
    .icon-search,
    #filter-rank,
    #filter-attribute,
    #filter-tribe {
        height: 42px;
    }

    .yokai-search {
        font-size: 16px;
        line-height: 22px;
    }

    #filter-rank,
    #filter-attribute,
    #filter-tribe {
        padding: 10px;
    }

    #filter-rank {
        width: 25%;
    }

    #filter-attribute {
        width: 35%;
    }

    #filter-tribe {
        width: 35%;
    }

    .icon-search {
        left: 7px;
        padding: 10px;
        width: 42px;
    }

    .medallium-list .yokai-content {
        padding: 10px;
    }

    .img-attribute,
    .img-rank,
    .img-trible {
        width: 30px;
        height: 30px;
    }

    .yokai-name-wrapper {
        margin-left: 10px;
    }

    .img-attribute,
    .img-trible {
        margin-left: 10px;
    }

    .no-yokai-wrapper {
        padding: 18px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 768px) and (max-width: 1024px) {
    .img-yokai-wrapper,
    .img-yokai {
        width: 100px;
        height: 100px;
    }

    .yokai-name-wrapper {
        margin-left: 15px;
    }
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .medallium {
        padding-top: 122px;
    }

    .yokai-filter-wrapper {
        padding: 0 10px 10px 10px;
        top: 60px;
    }

    .yokai-filter-left {
        width: 50%;
    }

    .yokai-filter-right {
        width: 49%
    }

    #filter-rank,
    #filter-attribute,
    #filter-tribe {
        width: 32.1%;
    }

    .icon-search {
        top: 0;
        left: 10px;
    }

    .img-yokai-wrapper,
    .img-yokai {
        width: 100px;
        height: 100px;
    }

    .yokai-name-wrapper,
    .img-attribute,
    .img-trible {
        margin-left: 15px;
    }

    .meddallium-yokai-number,
    .yokai-name {
        font-size: 21px;
        line-height: 29px;
    }

    .no-yokai-wrapper {
        padding: 18px;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    .meddallium-yokai-number-pc, .yokai-name-pc {
        font-size: 16px;
        line-height: 21px;
    }
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}
