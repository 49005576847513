.baffle-board-content-title {
    margin: 0;
    padding: 15px;
    background: #C3C3C3;
}

.table-baffle-board thead {
    color: var(--font-second-color);
    border: solid 1px #6daa73;
    background: #6daa73;
}

.table-baffle-board-1 thead th,
.table-baffle-board-2 thead th,
.table-baffle-board-1 tbody td,
.table-baffle-board-2 tbody td {
    width: 25%;
}

.table-baffle-board thead tr,
.table-baffle-board tbody tr {
    border-bottom: solid 1px #CCC;
}

.table-baffle-board tbody td {
    text-align: left;
    border-right: solid 1px #CCC;
}

.table-baffle-board tbody tr td:first-child {
    color: var(--font-second-color);
    border-left: solid 1px #CCC;
    background: #6daa73;
}

.table-baffle-board tbody td p {
    margin: 0;
}

.baffle-board-solution {
    padding: 0;
    position: relative;
}

.table-baffle-board tbody td a {
    top: 0;
    left: 0;
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
}

@media (max-width: 768px) {
    .baffle-board-content-title {
        font-size: 18px;
        line-height: 25px;
    }

    .table-baffle-board-1 thead th,
    .table-baffle-board-2 thead th,
    .table-baffle-board-3 thead th,
    .table-baffle-board-1 tbody td,
    .table-baffle-board-2 tbody td,
    .table-baffle-board-3 tbody td {
        font-size: 13px;
        line-height: 18px;
    }

    .table-baffle-board tbody td a {
        padding: 5px;
    }

    .baffle-board-solution {
        word-break: break-all;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}