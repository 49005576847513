.food {
    display: flex;
}

.food a {
    padding: 15px 20px;
    width: 50%;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.img-food,
.img-food-content {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.img-food {
    margin-right: 10px;
}

.food a,
.food a:hover,
.food a:focus,
.food a:visited {
    color: var(--font-second-color)
}

.table-food tr,
.table-food td {
    width: 25%;
}

.table-food thead {
    border-left: solid 1px #C3C3C3;
    border-right: solid 1px #C3C3C3;
    border-bottom: solid 1px #CCC;
    background: #C3C3C3;
}

.table-food tbody td {
    border: solid 1px #CCC;
}

@media (max-width: 768px) {
    .table-food tr,
    .table-food td {
        padding: 10px;    
    }

    .food a {
        padding: 15px 10px;
    }

    .img-food,
    .img-food-content {
        width: 35px;
        height: 35px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}