.table-changelog tr,
.table-changelog th,
.table-changelog td {
    text-align: left;
}

.table-changelog td {
    border: solid 1px #CCC;
}

.table-changelog p {
    margin: 0;
}

.changelog-content-title {
    font-weight: 700;
}

@media (max-width: 768px) {
    .table-changelog tr,
    .table-changelog td {
        padding: 10px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}