.qr-code-content,
.qr-code a {
    display: flex;
}

.qr-code-content {
    flex-wrap: wrap;
}

.qr-code {
    width: 50%;
}

.qr-code a {
    padding: 20px;
    font-weight: 700;
    align-items: center;
}

.icon-qr {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.qr-code,
.qr-code a,
.qr-code a:hover,
.qr-code a:focus,
.qr-code a:visited {
    color: var(--font-second-color);
}

.qr-list-content ul {
    margin: 0;
    padding: 0;
}

.qr-list-content li {
    list-style: none;
}

.qr-list-content a {
    padding: 10px 15px;
    width: 100%;
    border-bottom: solid 1px #CCC;
    display: inline-block;
}

.qr-code-slide-content .swiper {
    background: #333;
}

.qr-code-slide-content .swiper-slide {
    margin: 0;
    padding: 100px 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.img-qr-slide {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

.qr-code-slide-content .swiper-pagination {
    left: 0;
    bottom: 0;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    background: var(--font-second-color);
}

.slide-number {
    padding: 0 10px;
    font-weight: 700;
}

.slide-number:hover {
    cursor: pointer;
}

.slide-number-active {
    color: #DC143C;
}

@media (max-width: 768px) {
    .qr-code-slide-content .swiper {
        width: 100%;
        height: auto;
    }

    .qr-code-slide-content .swiper-slide {
        padding: 150px 50px;
    }

    .qr-code a {
        padding: 15px 10px;
        font-size: 13px;
        line-height: 18px;
    }

    .icon-qr {
        width: 35px;
        height: 35px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .qr-code-slide-content .swiper-slide {
        padding: 150px 50px;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    .qr-code-slide-content .swiper-slide {
        padding: 20px;
    }
}

@media screen and (max-width: 1366px) {
    .qr-code-slide-content .swiper-slide {
        padding: 20px;
    }
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}