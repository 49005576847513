#home {
    text-align: justify;
}

.home {
    padding: 15px;
}

.follow-title {
    font-weight: 700;
}

.socials-wrapper {
    margin: 0;
    padding: 0;
}

.socials-wrapper li {
    list-style: none;
    display: inline-block;
}

.socials-wrapper li:not(:last-child) {
    margin-right: 10px;
}

.icon-follow {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}

@media (max-width: 768px) {
    
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}