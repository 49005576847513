.password-wrapper {
    padding: 15px;
}

.password-content {
    text-align: center;
}

.password:not(:last-child) {
    margin-bottom: 15px;
}

.password a {
    display: inline-block;
}

.img-password-logo {
    object-fit: contain;
}

.password-1-content-wrapper,
.password-2-content-wrapper,
.password-3-content-wrapper,
.password-1-content,
.password-2-content,
.password-3-content {
    flex: 1;
    display: flex;
}

.password-1-content,
.password-2-content,
.password-3-content {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    justify-content: center;
    text-align: center;
}

.password-1-content a,
.password-2-content a,
.password-3-content a {
    padding: 30px;
    width: 100%;
    display: inline-block;
}

.password-1-content a,
.password-2-content a,
.password-3-content a,
.password-1-content a:hover,
.password-2-content a:hover,
.password-3-content a:hover,
.password-1-content a:focus,
.password-2-content a:focus,
.password-3-content a:focus,
.password-1-content a:visited,
.password-2-content a:visited,
.password-3-content a:visited {
    color: var(--font-second-color);
}

.password-1-content,
.password-2-content {
    width: 50%;
}

.table-password th,
.table-password td {
    text-align: left;
}

.table-password thead {
    color: var(--font-second-color);
    background: #333;
}

@media (max-width: 768px) {
    .password-wrapper {
        display: flex;
        flex-direction: column;
    }

    .password,
    .password-1-content,
    .password-2-content,
    .password-3-content {
        font-size: 18px;
        line-height: 25px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}