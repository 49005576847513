.contact-form-wrapper {
    padding: 15px;
}

.contact-form label {
    margin-bottom: 5px;
    font-weight: 700;
    display: inline-block;
}

.contact-form input,
.contact-form textarea,
.btn-contact-form {
    font-size: 16px;
    line-height: 22px;
    font-family: "Quick Sand", sans-serif;
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #CCC;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
}

.contact-form .require {
    color: #DC143C;
}

.btn-contact-form {
    padding: 15px 25px !important;
    font-weight: 700;
    border: none !important;
    border-radius: 5px;
    background: #FDDC69;
}

.btn-contact-form:hover {
    cursor: pointer;
}

.success-form-alert,
.failed-form-alert {
    padding: 10px 20px;
    font-weight: 700;
}

.success-form-alert {
    margin-top: 15px;
    margin-bottom: 0;
    border: solid 2px #FDDC69;
}

.failed-form-alert {
    border: solid 2px #DC143C;
}

@media (max-width: 768px) {
    .contact-form-wrapper {
        padding: 15px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .contact-form-wrapper {
        padding: 15px;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    .contact-form-wrapper {
        padding: 15px;
    }
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}