.site-navigation,
.medallium-navigation {
    top: 0;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 9999;
}

.navbar-content {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    background: #EFDE5B;
}

.navbar-content li {
    list-style: none;
}

.navbar-content li span,
.navbar-content li a {
    padding: 10px 15px;
    font-weight: 700;
    transition: .5s;
    display: inline-block;
}

.navbar-content .active,
.navbar-content li a:hover,
.navbar-content li a:focus {
    background: #C3C3C3;
}

@media (max-width: 768px) {
    .site-navigation,
    .medallium-navigation {
        display: none;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .site-navigation,
    .medallium-navigation {
        display: none;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}