.rare-special-soul-header,
.rare-special-soul-header span,
.rare-special-soul,
.rare-special-soul span,
.rare-special-soul a,
.rare-special-soul-desc {
    display: flex;
}

.rare-special-soul-header {
    font-weight: 700;
    background: #C3C3C3;
}

.special-soul,
.rare-special-soul-desc {
    padding: 15px;
}

.rare-special-soul-header span,
.rare-special-soul span,
.rare-special-soul a,
.rare-special-soul-desc {
    width: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: start;
}

.img-rare-special-soul {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.rare-special-soul-title {
    margin-top: 5px;
}

.rare-special-soul-plus,
.rare-special-soul-equal {
    width: 25% !important;
    justify-content: center !important;
}

.rare-special-soul-desc {
    border-top: solid 1px #CCC;
    border-bottom: solid 1px #CCC;
}

@media (max-width: 768px) {
    .img-rare-special-soul {
        width: 50px;
        height: 50px;
    }

    .special-soul,
    .rare-special-soul-desc {
        padding: 10px 5px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}
