.sidebar {
    top: 0;
    left: -100%;
    width: 100%;
    transition: left .3s ease-in-out;
    position: fixed;
    z-index: 99999;
}

.sidebar-content-wrapper {
    top: 0;
    left: 0;
    width: 30%;
    height: 100vh;
    background: var(--font-second-color);
    position: absolute;
    z-index: 1;
}

.sidebar-content {
    margin: -5px 0 0 0;
    padding: 0;
}

.sidebar-content li {
    list-style: none;
}

.sidebar-content li a {
    padding: 10px 15px;
    width: 100%;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
}

.sidebar-content li .active {
    background: #EFDE5B;
}

.sidebar-home-link,
.sidebar-attitudes-link,
.sidebar-food-link {
    border-bottom: solid 1px #CCC;
}

.bg-sidebar {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, .6);
    position: fixed;
    z-index: 9999;
}

.sidebar.open {
    left: 0;
}

.menu-back,
.menu-toggle {
    top: 0;
    bottom: 0;
    margin: 0 auto;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    position: absolute;
}

.menu-back {
    left: 0;
}

.menu-toggle {
    right: 0;
}

.menu-back:hover,
.menu-toggle:hover {
    cursor: pointer;
}

.icon-back,
.icon-navbar,
.icon-menu {
    object-fit: contain;
}

.icon-back,
.icon-navbar {
    width: 30px;
    height: 30px;
}

.icon-menu {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .icon-back,
    .icon-navbar {
        width: 20px;
        height: 20px;
    }

    .sidebar-content-wrapper {
        width: 75%;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    .icon-back,
    .icon-navbar {
        width: 20px;
        height: 20px;
    }

    .sidebar-content-wrapper {
        width: 75%;
    }
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}