.not-found {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.img-not-found {
    margin-bottom: 15px;
}

.not-found a {
    font-weight: 700;
    text-decoration: underline;
}

.not-found a,
.not-found a:hover,
.not-found a:focus,
.not-found a:visited {
    color: #E85A3D;
}

@media (max-width: 768px) {
    .not-found {
        padding: 20px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}