.privacy-policy {
    padding: 15px;
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.privacy-policy-content p:last-child {
    margin-bottom: 0;
}

.privacy-policy a {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .privacy-policy-content-title {
        font-size: 18px;
        line-height: 25px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    
}

@media (min-width: 800px) and (max-width: 1280px) {
    
}

@media (min-width: 810px) and (max-width: 1080px) {
    
}

@media (min-width: 600px) and (max-width: 960px) and (orientation: landscape) {
    
}

@media (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
    
}

@media (min-width: 810px) and (max-width: 1080px) and (orientation: landscape) {
    
}

@media (min-width: 992px) and (max-width: 1824px) {
    
}

@media (min-width: 1824px) {
    
}